import React from "react";
import { Button, DialogActions, DialogContent, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import { UpdateSupplementsConfigRequest } from "api/generatedApi";
import DialogTitleStyled from "components/shared/dialog/dialog-title-styled";
import { useTranslation } from "react-i18next";
import { DialogBaseProps } from "shared/dialog/types";
import { DialogList, StyledDialogTextfield, StyledListButton } from "../../admin.styled";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";

interface Props extends DialogBaseProps {
  data?: UpdateSupplementsConfigRequest;
  onSubmit: (data: UpdateSupplementsConfigRequest) => void;
}

export const AdminSupplementsDialog = (props: Props) => {
  const { data } = props;
  const { t } = useTranslation();

  const {
    formState: { isSubmitting },
    register,
    handleSubmit,
    getValues,
    control,
  } = useForm<UpdateSupplementsConfigRequest>({
    mode: "all",
    defaultValues: {
      supplementEntries: data?.supplementEntries,
    },
  });

  const { append, remove } = useFieldArray({
    control,
    name: "supplementEntries",
  });

  const onSubmit: SubmitHandler<UpdateSupplementsConfigRequest> = async (data) => {
    await props.onSubmit(data);

    return new Promise<void>((resolve) => {
      resolve();
    });
  };

  if (!data) return null;

  return (
    <Dialog maxWidth="sm" open={props.isOpen}>
      <DialogTitleStyled title={t("admin.supplements.edit.title")} onClose={props.onClose} isOpen={props.isOpen} handleIconClose={props.onClose} />
      <DialogContent sx={{ p: 3, pr: 5 }}>
        {getValues().supplementEntries && (
          <React.Fragment>
            {getValues().supplementEntries?.map((item, index) => {
              return (
                <DialogList key={index}>
                  <IconButton onClick={() => remove(index)}>
                    <DeleteIcon />
                  </IconButton>
                  <StyledDialogTextfield {...register(`supplementEntries.${index}.supplementId.value`)} variant="filled" label={t("admin.supplements.supplementId")} defaultValue={item.supplementId?.value} />
                  <StyledDialogTextfield {...register(`supplementEntries.${index}.supplementNumber.value`)} variant="filled" label={t("admin.supplements.supplementNumber")} defaultValue={item.supplementNumber?.value} />
                  <StyledDialogTextfield
                    {...register(`supplementEntries.${index}.supplementPercentage.value`, { valueAsNumber: true })}
                    variant="filled"
                    label={t("admin.supplements.supplementPercentage")}
                    defaultValue={item.supplementPercentage?.value}
                  />
                  <StyledDialogTextfield {...register(`supplementEntries.${index}.supplementText.value`)} multiline minRows={2} type="number" variant="filled" label={t("admin.supplements.supplementText")} defaultValue={item.supplementText?.value} />
                </DialogList>
              );
            })}
            <StyledListButton variant="contained" color="primary" onClick={() => append({ supplementId: { value: "" }, supplementNumber: { value: "" }, supplementPercentage: { value: 0 }, supplementText: { value: "" } })}>
              {t("admin.addToList")}
            </StyledListButton>
          </React.Fragment>
        )}
      </DialogContent>
      <DialogActions sx={{ pr: 3 }}>
        <Button variant="contained" sx={{ width: "160px" }} color="primary" disabled={isSubmitting} onClick={handleSubmit(onSubmit)}>
          {t("common.saveAndClose")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
