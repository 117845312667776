import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { ServerMetricsTableHead } from "./server-metrics-table-header";
import { useGetApiAdministrationMetricsQuery } from "api/generatedApi";
import { formatDate } from "utils/formats";

export default function ServerMetricsTable() {
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const metricsQuery = useGetApiAdministrationMetricsQuery({ page: page + 1, pageSize: pageSize });
  const rows = metricsQuery.data?.metrics ?? [];

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "80%", mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={"small"}>
            <ServerMetricsTableHead rowCount={rows.length} />
            <TableBody>
              {rows.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow key={`row-${index}`}>
                    <TableCell component="th" id={labelId} scope="row" padding="none">
                      {formatDate(new Date(row.metricDateTime ?? ""), true)}
                    </TableCell>
                    <TableCell align="right">
                      {row.successfulRequestsCount} - ({row.successfulRequestsAverageResponseTime?.toFixed(2)} ms)
                    </TableCell>
                    <TableCell align="right">
                      {row.failedRequestsCount} - ({row.failedRequestsAverageResponseTime?.toFixed(2)} ms)
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination rowsPerPageOptions={[5, 10, 25]} component="div" count={metricsQuery.data?.totalMetricsCount ?? 0} rowsPerPage={pageSize} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
      </Paper>
    </Box>
  );
}
